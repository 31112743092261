<template lang="pug">
div.notice
  Notice
</template>
<script>
import Notice from '@/components/notice/index'
export default {
  components: {
    Notice
  }
}
</script>
<style lang="scss" scoped>
.notice {
  padding: 0.3rem;
}
</style>
